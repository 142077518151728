'use client';

import { bool } from 'prop-types';

const Textarea = ({ invalid, disabled = false, ...props }) => {
  const classNames = [
    'bg-input-background-color',
    'text-input-text-color',
    'block',
    'w-full',
    'py-1.5',
    'px-2.5',
    'bg-clip-padding',
    'rounded',
    'border',
    'border-input-border-color',
    'disabled:opacity-60',
    'disabled:outline-none',
    'focus:outline-none',
    'focus:border',
    'focus:border-input-focus-color',
    'focus:bg-input-background-color',
    'focus:text-input-focus-color',
    'placeholder:text-input-placeholder-text-color',
  ];

  if (invalid) {
    classNames.push('!border-input-border-invalid-color');
    classNames.push('!focus:border-input-border-invalid-color');
  }

  return (
    <textarea
      readOnly={disabled}
      autoComplete="off"
      className={classNames.join(' ')}
      disabled={disabled}
      {...props}
    />
  );
};

Textarea.propTypes = {
  invalid: bool,
  disabled: bool,
};

export default Textarea;
