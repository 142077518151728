'use client';

import { bool, func, node, string } from 'prop-types';

import Icon from '../icon';

const CardButton = ({
  children,
  disabled = false,
  icon,
  onClick,
  type = 'button',
}) => {
  const classNames = [
    'w-full',
    'rounded',
    'bg-card-background-color',
    'text-card-text-color',
    'border',
    'border-card-border-color',
    'py-1.5',
  ];

  if (disabled) classNames.push('opacity-60');

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames.join(' ')}
    >
      <span className="mr-2">
        <Icon icon={icon} type="light" size={'lg'} />
      </span>
      {children}
    </button>
  );
};

CardButton.propTypes = {
  children: node.isRequired,
  disabled: bool,
  icon: string.isRequired,
  onClick: func,
  type: string,
};

export default CardButton;
