'use client';

import { bool, object } from 'prop-types';
import Image from 'next/image';
import { useMemo } from 'react';

const FileSource = ({ fileSource, priority = false }) => {
  const src = useMemo(() => (fileSource ? fileSource.url : ''), [fileSource]);

  return (
    <div>
      {src && (
        <Image
          src={src}
          width={fileSource.metadata.width}
          height={fileSource.metadata.height}
          className="h-auto w-full"
          sizes="100vw"
          alt={fileSource.fileName}
          priority={priority}
        />
      )}
      {!src && <>Loading...</>}
    </div>
  );
};

FileSource.propTypes = { fileSource: object, priority: bool };

export default FileSource;
