'use client';

import { array, bool, func, number, object } from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import Icon from '../icon';

const TextUpload = ({
  children,
  disabled,
  icon,
  maxFiles,
  mimeTypes,
  onError,
  onRead,
}) => {
  const classNames = [
    'border-dashed',
    'border-4',
    'border-upload-color',
    'text-upload-color',
    '!p-8',
    'text-center',
    'cursor-pointer',
  ];

  if (disabled) {
    classNames.push('border-upload-disabled-color');
    classNames.push('text-upload-disabled-color');
    classNames.push('cursor-auto');
  }

  const onDrop = useCallback(
    (files) => {
      for (const file of files) {
        const reader = new window.FileReader();
        reader.addEventListener('error', onError);
        reader.readAsDataURL(file);
        reader.onloadend = (readerEvent) => {
          onRead(readerEvent.target.result, file.name, file.type);
        };
      }
    },
    [onError, onRead]
  );

  const accept = useMemo(
    () => mimeTypes.reduce((acc, cur) => ({ ...acc, [cur]: [] }), {}),
    [mimeTypes]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    disabled,
  });

  return (
    <div {...getRootProps()} className={classNames.join(' ')}>
      <input {...getInputProps()} />
      <Icon type="solid" size="2x" icon={icon} />
      {children(isDragActive)}
    </div>
  );
};

TextUpload.propTypes = {
  children: func.isRequired,
  disabled: bool.isRequired,
  icon: object.isRequired,
  maxFiles: number.isRequired,
  mimeTypes: array.isRequired,
  onError: func.isRequired,
  onRead: func.isRequired,
};

export default TextUpload;
