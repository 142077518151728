'use client';

import classnames from 'classnames';
import { func } from 'prop-types';

import Icon from '../icon';

const className = classnames(
  'border-0',
  'inline-block',
  'align-middle',
  'text-card-link-color',
  'bg-background-color'
);

const MenuTogglerButton = ({ onClick }) => (
  <button
    aria-label="Toggle Nav"
    onClick={onClick}
    type="button"
    className={className}
  >
    <Icon icon={'bars'} size={'lg'} type="solid" />
  </button>
);

MenuTogglerButton.propTypes = { onClick: func.isRequired };

export default MenuTogglerButton;
