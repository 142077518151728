import { Suspense, lazy } from 'react';
import { string } from 'prop-types';

const Impl = lazy(() => import('./impl'));

const Icon = (props) => (
  <Suspense fallback={<div className={`fa-${props.size}`}>&nbsp;</div>}>
    <Impl {...props} />
  </Suspense>
);

Icon.propTypes = { size: string };

export default Icon;
