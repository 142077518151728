'use client';

import { array, func, string } from 'prop-types';
import { useCallback } from 'react';

import Icon from '../icon';

const IconSelect = ({ icon, options, selectedOption, setSelectedOption }) => {
  const selectedClassName = useCallback(
    (option) =>
      option !== selectedOption
        ? 'cursor-pointer px-1.5'
        : 'cursor-pointer px-1.5 rounded-md bg-pill-background-color',
    [selectedOption]
  );

  return (
    <div className={'flex flex-row'}>
      {options.map((option) => (
        <div
          className={selectedClassName(option)}
          key={option}
          onClick={() => setSelectedOption(option)}
        >
          <Icon type="light" icon={icon(option)} size={'1x'} />
        </div>
      ))}
    </div>
  );
};

IconSelect.propTypes = {
  icon: func.isRequired,
  options: array.isRequired,
  selectedOption: string,
  setSelectedOption: func.isRequired,
};

export default IconSelect;
