'use client';

import { number, oneOfType, string } from 'prop-types';
import { useEffect, useState } from 'react';

const TimeSince = ({ locale, value }) => {
  const [elapsed, setElapsed] = useState('now');

  useEffect(() => {
    (async () => {
      const { default: TimeAgo } = await import('javascript-time-ago');

      if (locale === 'en') {
        const { default: en } = await import('javascript-time-ago/locale/en');
        TimeAgo.addLocale(en);
      } else if (locale === 'de') {
        const { default: de } = await import('javascript-time-ago/locale/de');
        TimeAgo.addLocale(de);
      }

      const timeAgo = new TimeAgo(locale);

      setElapsed(timeAgo.format(parseInt(value, 10), 'mini'));
    })();
  }, [locale, value]);

  return elapsed;
};

TimeSince.propTypes = {
  locale: string.isRequired,
  value: oneOfType([string, number]).isRequired,
};

export default TimeSince;
