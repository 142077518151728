'use client';

import { Suspense, lazy } from 'react';
import { string } from 'prop-types';

const Impl = lazy(() => import('./impl'));

const Select = (props) => (
  <Suspense fallback={<input type="select" hidden id={props.inputId} />}>
    <Impl {...props} />
  </Suspense>
);

Select.propTypes = {
  inputId: string.isRequired,
};

export default Select;
