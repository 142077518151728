'use client';

import { array, bool, func, object } from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import Icon from '../icon';

const FileSourceUpload = ({
  children,
  disabled,
  icon,
  mimeTypes,
  onError,
  onRead,
}) => {
  const classNames = [
    'border-dashed',
    'border-4',
    'border-upload-color',
    'text-upload-color',
    '!p-8',
    'text-center',
    'cursor-pointer',
  ];

  if (disabled) {
    classNames.push('border-upload-disabled-color');
    classNames.push('text-upload-disabled-color');
    classNames.push('cursor-auto');
  }

  const onDrop = useCallback(
    (files) => {
      const file = files[0];
      if (!file) return onError('FILE_NOT_FOUND');

      const fileData = new window.Blob([file]);
      if (fileData.size > 4194304) return onError('FILE_SIZE_TOO_LARGE');

      const reader = new window.FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (readerEvent) => {
        const image = new Image();
        image.src = readerEvent.target.result;

        image.onload = function () {
          onRead(readerEvent.target.result, file.name, file.type, {
            width: image.width,
            height: image.height,
          });
        };
      };
    },
    [onError, onRead]
  );

  const accept = useMemo(
    () => mimeTypes.reduce((acc, cur) => ({ ...acc, [cur]: [] }), {}),
    [mimeTypes]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
    disabled,
  });

  return (
    <div {...getRootProps()} className={classNames.join(' ')}>
      <input {...getInputProps()} />
      <Icon type="solid" size="2x" icon={icon} />
      <p>{children(isDragActive)}</p>
    </div>
  );
};

FileSourceUpload.propTypes = {
  children: func.isRequired,
  disabled: bool.isRequired,
  icon: object.isRequired,
  mimeTypes: array.isRequired,
  onError: func.isRequired,
  onRead: func.isRequired,
};

export default FileSourceUpload;
