'use client';

import { func, node, string } from 'prop-types';
import classnames from 'classnames';

import Icon from '../icon';

const className = classnames(
  'flex',
  'flex-row',
  'background-input-background-color',
  'border',
  'border-input-border-color',
  'rounded',
  'p-2',
  'gap-2'
);

const Toggle = ({ children, icon, onClick }) => (
  <div className={className}>
    <div
      onClick={onClick}
      className="flex items-center cursor-pointer text-input-icon-color"
    >
      <Icon size="lg" icon={icon} type="solid" />
    </div>
    <div className="text-xs leading-normal">{children}</div>
  </div>
);

Toggle.propTypes = {
  children: node.isRequired,
  icon: string.isRequired,
  onClick: func.isRequired,
};

export default Toggle;
