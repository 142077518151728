'use client';

import { Suspense, lazy } from 'react';
import { func } from 'prop-types';

const Impl = lazy(() => import('./impl'));

const Toaster = ({ onNotify = () => {} }) => (
  <Suspense fallback={null}>
    <Impl onNotify={onNotify} />
  </Suspense>
);

Toaster.propTypes = { onNotify: func };

export default Toaster;
